<template>
    <div class="comunicati-stampa-page">
        <basic-page :pagedata="this.pageData" bannerimg="/img/comunicati-stampa-banner.jpg">
            <div class="comunicati-stampa-container container-beige intro container">
            </div>
            <div class="container">
                <div v-for="(item, index) of this.pressData" :key="index">
                    <a class="comunicato" :href="endpoint + item.field_press_release_attachment.attributes.uri.url" download
                        target="_blank">
                        <p class="date">{{ convertDate(item.attributes.field_press_release_date) }}</p>
                        <h2>{{ item.attributes.title }}</h2>
                        <a  class="cta-link">
                            {{ lang == 'it' ? 'Leggi il comunicato' : 'Read the article'}}
                        </a>
                    </a>
                </div>
            </div>
        </basic-page>
    </div>
</template>

<script>
import BasicPage from './BasicPage.vue'
import { fetchSinglePage, getTranslation, fetchNodes } from '../libs/drupalClient'
import { reWriteDate } from '../libs/utils'
export default {
    components: { BasicPage },
    name: 'comunicati-stampa',
    data: () => {
        return {
            currentPageId: "86a0545b-86e2-4497-9b9c-363815d8d700",
            pageData: { attributes: {} },
            pressData: {}
        }
    },
    methods: {
        convertDate(d) {
            return reWriteDate(d)
        }
    },
    computed: {
        lang() {
            return this.$store.state.currentLang
        },
        endpoint(){
            return process.env.VUE_APP_ENDPOINT
        }
    },
    mounted() {
        fetchSinglePage(this.currentPageId, this.lang)
            .then(res => {
                this.pageData = res[0]
            })
        fetchNodes('press_release', {
            include: ['field_press_release_attachment']
        }, this.lang)
        .then(res => {
            this.pressData = res.sort((a, b) => {
                return a.attributes.field_press_release_date > b.attributes.field_press_release_date ? -1 : 1
            })
            //console.log(this.pressData)
        })
    },
    watch: {
        lang() {
            getTranslation(this.$route.name, this.lang)
            .then(res => {
                this.$router.push({path: res})
            })

            fetchSinglePage(this.currentPageId, this.lang)
            .then(res => {
                this.pageData = res[0]
            }) 
            fetchNodes('press_release', {
                include: ['field_press_release_attachment']
            }, this.lang)
            .then(res => {
                this.pressData = res.sort((a, b) => {
                    return a.attributes.field_press_release_date > b.attributes.field_press_release_date ? -1 : 1
                })
            })
        }
    }
}
</script>

<style lang="scss">
    @import "../styles/colors";
    .cta-link {
        color: $mainColor;
        margin-top: 1rem;
    }
    .comunicati-stampa-container.container-beige.intro.container {
        padding: 2rem 2rem;
        background-color: #ffffff;
    }

    .comunicati-stampa-container+.container {
        margin: 3rem auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        &>div {
            width: 33.33%;
        }
    }

    .comunicato {
        margin: 0.51rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background-color: #F7F4F2;
        text-decoration: none;
        padding: 1rem 2rem 3rem 2rem;
        height: 600px;
        position: relative;
        box-shadow: 0px 0px 0px 0px $mainColor inset;
        transition: 0.3s ease;

        &:before {
            content: "";
            background-image: url("../assets/attachment-icon-comunicati.png");
            width: 172px;
            height: 172px;
            position: absolute;
            top: 40px;
            left: 50%;
            transform: translateX(-50%);
        }

        .date {
            position: relative;
            color: #D2BC75;

            &:before {
                content: "";
                width: 40px;
                height: 3px;
                background-color: #D2BC75;
                position: absolute;
                top: -20px;
                left: 0;
            }
        }

        h2 {
            margin-top: 1rem;
            font-size: 2rem;
            line-height: 2.3rem;
        }
    }

    .comunicato:hover {
        box-shadow: 0px 0px 0px 6px $mainColor inset;
        transition: 0.3s ease;
    }


    @media (max-width:1500px) {
        .comunicati-stampa-container+.container>div {
            width: 50%;
        }

        .comunicato {
            height: 520px;
        }
    }

    @media (max-width:992px) {
        .comunicati-stampa-container+.container>div {
            width: 100%;
        }
    }

    @media (max-width:768px) {
        .comunicato {
            height: auto;
            padding-top: 190px;

            h2 {
                font-size: 1.625rem;
                line-height: 2rem;
            }
        }

        .comunicato:before {
            top: 0px;
            transform: translateX(-50%) scale(0.7);
        }
    }
</style>